import React, { useState, useEffect, useRef, RefObject } from "react";
import { Outlet, NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Layout.module.css";

import { useLogin } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";
import { IconButton } from "@fluentui/react";


// AUDI CUSTOM ENHANCEMENT - Start
import frontendConfig from "../../frontend_config.json";
import {LicensePopup} from "../../audi/components/FooterArea/LicensePopup";
import {Footer} from "../../audi/components/FooterArea/Footer";
// AUDI CUSTOM ENHANCEMENT - End

const Layout = () => {
    const { t } = useTranslation();

    // page title
    useEffect(() => {
        document.title = frontendConfig.common.tab_title
    }, []);

    // license popup
    const [licenseVisible, setLicenseVisible] = useState(false);

    // menu handling
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef: RefObject<HTMLDivElement> = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        if (menuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuOpen]);

    return (
        <div className={styles.layout}>
            {licenseVisible && <LicensePopup setLicenseVisible={setLicenseVisible}/>}

            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer} ref={menuRef}>

                    {frontendConfig.header.title.visible &&
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h3 className={styles.headerTitle}>{frontendConfig.header.title.caption}</h3>
                        </Link>
                    }

                    {frontendConfig.header.navbar.visible &&
                        <nav>
                            <ul className={`${styles.headerNavList} ${menuOpen ? styles.show : ""}`}>
                                {
                                    frontendConfig.header.navbar.buttons.map((navbar_button) =>
                                        <li className={styles.headerNavLeftMargin}>
                                            <NavLink
                                                to={navbar_button.url_suffix}
                                                className={({isActive}) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                                onClick={() => setMenuOpen(false)}
                                            >
                                                {navbar_button.caption}
                                            </NavLink>
                                        </li>
                                    )
                                }
                            </ul>
                        </nav>
                    }

                    <div className={styles.headerRight}>
                        {frontendConfig.header.stage.visible &&
                            <h4 className={styles.headerRightText}>{frontendConfig.header.stage.caption}</h4>
                        }

                        <div className={styles.loginMenuContainer}>
                            {useLogin && <LoginButton/>}
                            <IconButton
                                iconProps={{iconName: "GlobalNavButton"}}
                                className={styles.menuToggle}
                                onClick={toggleMenu}
                                ariaLabel={t("labels.toggleMenu")}
                            />
                        </div>
                    </div>
                </div>
            </header>

            {!licenseVisible && <Outlet/>}

            <Footer setLicenseVisible={setLicenseVisible}/>
        </div>
    );
};

export default Layout;
