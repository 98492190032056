import React from "react";

import styles from "./Footer.module.css";
import frontendConfig from "../../../../frontend_config.json";

// AUDI CUSTOM ENHANCEMENT
// The original app does not contain a footer.

interface Props {
    setLicenseVisible: (visible: boolean) => void;
}

export const Footer = ({ setLicenseVisible }: Props) => {
    const licenseButtonConfig = frontendConfig.footer.license_button
    const linkButtonsConfig = frontendConfig.footer.link_buttons

    return (
        <footer className={styles.footer} role={"contentinfo"}>

            <div className={styles.footerDisclaimerContainer}>
                <p>{frontendConfig.footer.disclaimer}</p>
            </div>

            <div className={styles.footerLinksContainer}>
                {
                    // license button
                    licenseButtonConfig.visible &&
                    <a className={styles.linkButton} onClick={() => setLicenseVisible(true)}>
                        {licenseButtonConfig.caption}
                    </a>
                }
                {
                    // link buttons
                    linkButtonsConfig.map((linkButton) =>
                        <a className={styles.linkButton} href={linkButton.link} target={"_blank"}>
                            {linkButton.caption}
                        </a>
                    )
                }
            </div>

        </footer>
    );
};