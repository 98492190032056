import { ExamplePrompt } from "./ExamplePrompt";
import styles from "./ExamplePrompts.module.css";

// AUDI CUSTOM ENHANCEMENT
// Compared to the original ExampleList class, this enhancement features a prompts parameter.

interface Props {
    prompts: string[],
    onExampleClicked: (value: string) => void;
}

export const ExamplePrompts = ({ prompts, onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {prompts.map((question, i) => (
                <li key={i}>
                    <ExamplePrompt
                        text={question} 
                        value={question} 
                        onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
