import React from "react";

import styles from "./LicensePopup.module.css";
import licenseFile from "../../../../assets/License.txt"

// AUDI CUSTOM ENHANCEMENT
// The original app does not contain a License Popup.

interface Props {
    setLicenseVisible: (visible: boolean) => void;
}

export const LicensePopup = ({ setLicenseVisible }: Props) => {
    const [licenseText, setLicenseText] = React.useState("Lade Lizenztext ...");

    fetch(licenseFile)
        .then((response) => response.text())
        .then((content) => setLicenseText(content));

    return (
        <div className={styles.backdrop}>
            <div className={styles.popup}>
                <h1>License</h1>
                <textarea
                    className={styles.text}
                    readOnly={true}
                    value={licenseText}
                />
                <button className={styles.closeButton} onClick={() => setLicenseVisible(false)}>
                    Schließen
                </button>
            </div>
        </div>
    );
};
